import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52e13c5d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field-prepend" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_emoji = _resolveComponent("tm-emoji")!
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_form_line = _resolveComponent("tm-form-line")!
  const _component_sharing_select = _resolveComponent("sharing-select")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "saveView",
    size: "small",
    "modal-title": _ctx.modalTitle
  }, {
    "modal-content": _withCtx(() => [
      _createVNode(_component_tm_form_line, {
        class: "mb-4",
        label: "View name"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_field, {
            modelValue: _ctx.view.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.view.name) = $event)),
            placeholder: "Enter view name"
          }, _createSlots({ _: 2 }, [
            (_ctx.showEmoji)
              ? {
                  name: "prepend",
                  fn: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_tm_emoji, { name: "mobile" })
                    ])
                  ])
                }
              : undefined
          ]), 1032, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_tm_form_line, { label: "View is visible to" }, {
        default: _withCtx(() => [
          _createVNode(_component_sharing_select)
        ]),
        _: 1
      })
    ]),
    "modal-footer-button": _withCtx(() => [
      _createVNode(_component_tm_button, {
        size: "large",
        color: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.btnText), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-title"]))
}