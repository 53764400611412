
import { defineComponent, reactive, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmEmoji from '@/components/shared/TmEmoji.vue'
import SharingSelect from '@/components/shared/templates/SharingSelect.vue'

export default defineComponent({
  components: {
    TmButton,
    TmFormLine,
    SharingSelect,
    TmModal,
    TmEmoji,
  },
  props: {
    modalTitle: {
      type: String,
      default: 'Save view',
    },
    showEmoji: {
      type: Boolean,
    },
    btnText: {
      type: String,
      default: 'Save',
    },
  },
  setup() {
    const view = reactive({
      name: '',
      visible: 'all',
    })
    const teams = ref([
      {
        uid: 1,
        title: 'Sales',
        description: '(16 team members)',
      },
      {
        uid: 2,
        title: 'Support',
        description: '(32 team members)',
      },
      {
        uid: 3,
        title: 'Devs',
        description: '(12 team members)',
      },
    ])
    const users = ref([
      {
        uid: 1,
        title: 'Marcus Greene',
        description: '(markus.greene@textagic.com)',
        avatar: 'https://randomuser.me/api/portraits/women/33.jpg',
      },
      {
        uid: 2,
        title: 'Mark Brown',
        description: '(markus.greene@textagic.com)',
        avatar: 'https://randomuser.me/api/portraits/women/34.jpg',
      },
      {
        uid: 3,
        title: 'Paul Lengi',
        description: '(markus.greene@textagic.com)',
        avatar: 'https://randomuser.me/api/portraits/women/35.jpg',
      },
      {
        uid: 4,
        title: 'Stella Nie',
        description: '(markus.greene@textagic.com)',
        avatar: 'https://randomuser.me/api/portraits/women/36.jpg',
      },
    ])

    const specificUsers = ref([])
    const specificTeams = ref([])

    return {
      view,
      teams,
      users,
      specificUsers,
      specificTeams,
    }
  },
})
